// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('userLogs/getData', async (params) => {
    const url = `/api/users/logs`
    const response = await axios.get(url, {params})
    debugger
    return {
        params,
        data: response.data.users,
        totalPages: response.data.pageCount
    }
})

export const appUserLogslice = createSlice({
    name: 'appUserLogs',
    initialState: {
        data: [],
        total: 1,
        params: {}
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export default appUserLogslice.reducer
