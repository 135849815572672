import {Eye, Trash2, Key, Check} from 'react-feather'
import * as React from 'react'
import { Button } from 'reactstrap'

export const columns = [
    {
        name: 'Grup Adı',
        sortable: true,
        minWidth: '172px',
        sortField: 'companyGroupName',
        selector: row => row.companyGroupName,
        cell: row => row.companyGroupName
    },
    {
        name: 'Firma Adı',
        sortable: true,
        minWidth: '172px',
        sortField: 'companyName',
        selector: row => row.companyName,
        cell: row => row.companyName
    },
    {
        name: 'Sonuç',
        minWidth: '138px',
        sortable: true,
        sortField: 'result',
        selector: row => row.result,
        cell: row => row.result
    },
    {
        name: 'Sigorta Prim Aslı',
        minWidth: '138px',
        sortable: true,
        sortField: 'insuranceBonusOriginal',
        selector: row => row.insuranceBonusOriginal,
        cell: row => row.insuranceBonusOriginal
    },
    {
        name: 'İşsizlik Prim Aslı',
        minWidth: '138px',
        sortable: true,
        sortField: 'unemploymentBonusOriginal',
        selector: row => row.unemploymentBonusOriginal,
        cell: row => row.unemploymentBonusOriginal
    },
    {
        name: 'Eğitime Katkı Payı Aslı',
        minWidth: '138px',
        sortable: true,
        sortField: 'educationContributionShareOriginal',
        selector: row => row.educationContributionShareOriginal,
        cell: row => row.educationContributionShareOriginal
    },
    {
        name: 'Özel İletişim Vergisi Aslı',
        minWidth: '138px',
        sortable: true,
        sortField: 'privateCommunicationTaxOriginal',
        selector: row => row.privateCommunicationTaxOriginal,
        cell: row => row.privateCommunicationTaxOriginal
    },
    {
        name: 'Damga Vergisi Aslı',
        minWidth: '138px',
        sortable: true,
        sortField: 'stampTaxOriginal',
        selector: row => row.stampTaxOriginal,
        cell: row => row.stampTaxOriginal
    },
    {
        name: 'İdari Para Cezası Aslı',
        minWidth: '138px',
        sortable: true,
        sortField: 'administrativeFineOriginal',
        selector: row => row.administrativeFineOriginal,
        cell: row => row.administrativeFineOriginal
    },
    {
        name: 'Borç Asılları Genel Toplamı',
        minWidth: '138px',
        sortable: true,
        sortField: 'totalOfDebtPrincipals',
        selector: row => row.totalOfDebtPrincipals,
        cell: row => row.totalOfDebtPrincipals
    },
    {
        name: 'İşlemler',
        minWidth: '300px',
        cell: row => (
            <div className=" d-flex gap-1">
                {row.pdfWithBarcode !==  null ? <a href={row.pdfWithBarcode} target="_blank" rel="noopener noreferrer">
                    <Button className="add-new-user" color="primary" type="button">
                        PDF
                    </Button>
                </a> : null}

                {row.pageScreenshot !== null ? <a href={row.pageScreenshot} target="_blank" rel="noopener noreferrer">
                    <Button className="add-new-user" color="primary" type="button">
                        Ekran Görüntüsü
                    </Button>
                </a> : null}
            </div>
        )
    }

]
