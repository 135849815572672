import { LuFilter } from "react-icons/lu"
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { setSelectedCompanies, setSelectedWorkAccidentDateMonths, setSelectedWorkAccidentDateYears } from "../store"
import CustomScrollbar from "../../visit/list/custom-scrollbar"

const FilterComponent = ({ toggleDropdown, isOpen, type, name }) => {
  const dispatch = useDispatch()
  const companyOptions = useSelector((state) => state.workAccident.companyOptions)
  const selectedCompanies = useSelector((state) => state.workAccident.selectedCompanyIds)
  const selectedWorkAccidentDateYears = useSelector((state) => state.workAccident.selectedWorkAccidentDateYears)
  const selectedWorkAccidentDateMonths = useSelector((state) => state.workAccident.selectedWorkAccidentDateMonths)

  // İşyeri checkbox işlemleri
  const handleCompanyCheckboxChange = (event, companyId) => {
    if (event.target.checked) {
      dispatch(setSelectedCompanies([...selectedCompanies, companyId]))
    } else {
      dispatch(setSelectedCompanies(selectedCompanies.filter((id) => id !== companyId)))
    }
  }

  // WorkAccident DATE YEAR checkbox işlemleri
  const handleWorkAccidentDateYearCheckboxChange = (event, year) => {
    if (event.target.checked) {
      dispatch(setSelectedWorkAccidentDateYears([...selectedWorkAccidentDateYears, year]))
    } else {
      dispatch(
        setSelectedWorkAccidentDateYears(
          selectedWorkAccidentDateYears.filter((selectedYear) => selectedYear !== year)
        )
      )
    }
  }

  // WorkAccident DATE MONTH checkbox işlemleri
  const handleWorkAccidentDateMonthCheckboxChange = (event, monthIndex) => {
    if (event.target.checked) {
      dispatch(setSelectedWorkAccidentDateMonths([...selectedWorkAccidentDateMonths, monthIndex]))
    } else {
      dispatch(
        setSelectedWorkAccidentDateMonths(
          selectedWorkAccidentDateMonths.filter((selectedMonth) => selectedMonth !== monthIndex)
        )
      )
    }
  }

  // İşyeri checkbox listesini oluşturur
  const renderCompanyCheckboxList = () => {
    return companyOptions.map((company) => {
      const isSelected = selectedCompanies.includes(company.companyId)
      return (
        <div
          key={company.companyId}
          className="d-flex align-items-center"
          style={{
            marginBottom: '8px',
            padding: '4px',
            borderRadius: '4px',
            backgroundColor: isSelected ? '#dcd4fc' : 'transparent' // Mor seçili arka plan
          }}
        >
          <input
            type="checkbox"
            id={`company-${company.companyId}`}
            checked={isSelected}
            onChange={(e) => handleCompanyCheckboxChange(e, company.companyId)}
            style={{
              accentColor: '#724EFF' // Mor checkbox işareti
            }}
          />
          <label
            htmlFor={`company-${company.companyId}`}
            className="ms-2"
            style={{
              color: isSelected ? '#724EFF' : 'inherit' // Seçiliyse yazı rengi mor
            }}
          >
            {company.companyName}
          </label>
        </div>
      )
    })
  }

  const years = [2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000]
  const months = [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık'
  ]

  // renderWorkAccidentDatePicker Fonksiyonu
  const renderWorkAccidentDatePicker = () => {
    return (
      <div className="d-flex">
        {/* Yıllar Sütunu */}
        <CustomScrollbar sx={{ borderRight: '1px solid #ddd' }}>
          <p className="fw-bold">Yıllar</p>
          {years.map(year => (
            <div key={year} className="d-flex align-items-center mb-2">
              <input
                type="checkbox"
                id={`year-${year}`}
                style={{
                  accentColor: '#724EFF'
                }}
                onChange={(e) => handleWorkAccidentDateYearCheckboxChange(e, year)}
                checked={selectedWorkAccidentDateYears.includes(year)}
              />
              <label htmlFor={`year-${year}`} className="ms-2">
                {year}
              </label>
            </div>
          ))}
        </CustomScrollbar>

        {/* Aylar Sütunu */}
        <CustomScrollbar sx={{ borderRight: '1px solid #ddd' }}>
          <p className="fw-bold">Aylar</p>
          {months.map((month, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <input
                type="checkbox"
                id={`month-${index + 1}`}
                style={{
                  accentColor: '#724EFF'
                }}
                onChange={(e) => handleWorkAccidentDateMonthCheckboxChange(e, index + 1)}
                checked={selectedWorkAccidentDateMonths.includes(index + 1)}
              />
              <label htmlFor={`month-${index + 1}`} className="ms-2">
                {month}
              </label>
            </div>
          ))}
        </CustomScrollbar>
      </div>
    )
  }

  // Dropdown içeriği
  const renderDropdownContent = () => {
    switch (type) {
      case 'isyeri':
        return (
          <div className="px-1" style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <p>İşyeri Seçiniz</p>
            {renderCompanyCheckboxList()}
          </div>
        )
      case 'workAccidentDate':
        return (
          <div className="px-1" style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <p>Yıl - Ay Seçiniz</p>
            {renderWorkAccidentDatePicker()}
          </div>
        )
      default:
        return <DropdownItem>Varsayılan Filtre</DropdownItem>
    }
  }

  return (
    <div className="d-flex align-items-center">
      <span>{name}</span>
      <Dropdown isOpen={isOpen} toggle={toggleDropdown} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
        <DropdownToggle color="outline" className="btn-icon">
          <LuFilter size={14} />
        </DropdownToggle>
          <DropdownMenu container="body" style={{ maxHeight: '300px', overflowY: 'auto' }} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
          {renderDropdownContent()}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default FilterComponent
