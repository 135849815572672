import React from 'react'
import CompanyList from './company-list'
import ResultList from './result-list'

const DebtQuestioning = () => {
         return (
        <>
        <ResultList />
        <CompanyList />
        </>
    )
}

export default DebtQuestioning