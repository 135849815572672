import {Eye, Trash2, Key, Check} from 'react-feather'
import * as React from 'react'
import { Button } from 'reactstrap'
import { store } from '@store/store'
import { startDebtQuestioning } from '../store/'

export const columns = [
    {
        name: 'Grup Adı',
        sortable: true,
        minWidth: '172px',
        sortField: 'groupName',
        selector: row => row.groupName,
        cell: row => row.groupName
    },
    {
        name: 'Firma Adı',
        sortable: true,
        minWidth: '172px',
        sortField: 'name',
        selector: row => row.name,
        cell: row => row.name
    },
    {
        name: 'Sicil Numarası',
        minWidth: '138px',
        sortable: true,
        sortField: 'registirationNumber',
        selector: row => row.registirationNumber,
        cell: row => row.registirationNumber
    },
    {
        name: 'Vergi Numarası',
        minWidth: '230px',
        sortable: true,
        sortField: 'taxNumber',
        selector: row => row.taxNumber,
        cell: row => (row.taxNumber ? (`00${row.taxNumber}`).slice(-10) : '')
    },
    {
        name: 'İşlemler',
        minWidth: '100px',
        cell: row => (
            <div className='column-action'>
                <Button className='add-new-user me-1' color='primary' onClick={(e) => {
                      e.preventDefault()
                    store.dispatch(startDebtQuestioning(row.id))
                    }}>
                    Borç Sorgula
                </Button>

            </div>
        )
    }
]
