import { lazy } from 'react'

const UserManagement = lazy(() => import('../../views/pages/user-management/index'))
const UserFileManager = lazy(() => import('../../views/pages/UserFileManager/index'))
const UserLogs = lazy(() => import('../../views/pages/userLogs/list/index'))

const UserManagementRoutes = [
  {
    path: '/kullanici-yonetim',
    element: <UserManagement />
  },
  {
    path: '/kullanici-loglari',
    element: <UserLogs />
  },
  {
    path: '/dosya-yonetimi',
    element: <UserFileManager />
  }
]

export default UserManagementRoutes