import { LuFilter } from "react-icons/lu"
import { useDispatch, useSelector } from 'react-redux'
import { setPendingSelectedCompanies, setPendingSelectedLaws, setPendingSelectedPeriods } from '../store'
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

const FilterComponent = ({ toggleDropdown, isOpen, type, name }) => {
    const dispatch = useDispatch()
    const companyOptions = useSelector(state => state.edeclarations.pendingCompanyOptions)
    const periodOptions = useSelector(state => state.edeclarations.pendingPeriodOptions)
    const lawOptions = useSelector(state => state.edeclarations.pendingLawOptions)
    const selectedCompanies = useSelector(state => state.edeclarations.pendingSelectedCompanies)
    const selectedPeriods = useSelector(state => state.edeclarations.pendingSelectedPeriods)
    const selectedLaws = useSelector(state => state.edeclarations.pendingSelectedLaws)

    // İşyeri checkbox işlemleri
    const handleCompanyCheckboxChange = (event, companyId) => {
      if (event.target.checked) {
        dispatch(setPendingSelectedCompanies([...selectedCompanies, companyId]))
      } else {
        dispatch(setPendingSelectedCompanies(selectedCompanies.filter(id => id !== companyId)))
      }
    }

    // Dönem checkbox işlemleri
    const handlePeriodCheckboxChange = (event, period) => {
      if (event.target.checked) {
        dispatch(setPendingSelectedPeriods([...selectedPeriods, period]))
      } else {
        dispatch(setPendingSelectedPeriods(selectedPeriods.filter(p => p !== period)))
      }
    }

    // Kanun checkbox işlemleri
    const handleLawCheckboxChange = (event, law) => {
      if (event.target.checked) {
        dispatch(setPendingSelectedLaws([...selectedLaws, law]))
      } else {
        dispatch(setPendingSelectedLaws(selectedLaws.filter(l => l !== law)))
      }
    }


    // İşyeri checkbox listesini oluşturur
    const renderCompanyCheckboxList = () => {
      return companyOptions.map(company => {
        const isSelected = selectedCompanies.includes(company.companyId)
        return (
          <div
            key={company.companyId}
            className="d-flex align-items-center"
            style={{
              marginBottom: '8px',
              padding: '4px',
              borderRadius: '4px',
              backgroundColor: isSelected ? '#dcd4fc' : 'transparent' // Mor seçili arka plan
            }}
          >
            <input
              type="checkbox"
              id={`company-${company.companyId}`}
              checked={isSelected}
              onChange={(e) => handleCompanyCheckboxChange(e, company.companyId)}
              style={{
                accentColor: '#724EFF' // Mor checkbox işareti
              }}
            />
            <label
              htmlFor={`company-${company.companyId}`}
              className="ms-2"
              style={{
                color: isSelected ? '#724EFF' : 'inherit' // Seçiliyse yazı rengi mor
              }}
            >
              {company.companyName}
            </label>
          </div>
        )
      })
    }

    // Dönem checkbox listesini oluşturur
    const renderPeriodCheckboxList = () => {
      return periodOptions.map(period => {
        const isSelected = selectedPeriods.includes(period)
        return (
          <div
            key={period}
            className="d-flex align-items-center"
            style={{
              marginBottom: '8px',
              padding: '4px',
              borderRadius: '4px',
              backgroundColor: isSelected ? '#dcd4fc' : 'transparent' // Mor seçili arka plan
            }}
          >
            <input
              type="checkbox"
              id={`period-${period}`}
              checked={isSelected}
              onChange={(e) => handlePeriodCheckboxChange(e, period)}
              style={{
                accentColor: '#724EFF' // Mor checkbox işareti
              }}
            />
            <label
              htmlFor={`period-${period}`}
              className="ms-2"
              style={{
                color: isSelected ? '#724EFF' : 'inherit' // Seçiliyse yazı rengi mor
              }}
            >
              {period}
            </label>
          </div>
        )
      })
    }

    // Kanun checkbox listesini oluşturur
    const renderLawCheckboxList = () => {
      return lawOptions.map(law => {
        const isSelected = selectedLaws.includes(law)
        return (
          <div
            key={law}
            className="d-flex align-items-center"
            style={{
              marginBottom: '8px',
              padding: '4px',
              borderRadius: '4px',
              backgroundColor: isSelected ? '#dcd4fc' : 'transparent' // Mor seçili arka plan
            }}
          >
            <input
              type="checkbox"
              id={`law-${law}`}
              checked={isSelected}
              onChange={(e) => handleLawCheckboxChange(e, law)}
              style={{
                accentColor: '#724EFF' // Mor checkbox işareti
              }}
            />
            <label
              htmlFor={`law-${law}`}
              className="ms-2"
              style={{
                color: isSelected ? '#724EFF' : 'inherit' // Seçiliyse yazı rengi mor
              }}
            >
              {law}
            </label>
          </div>
        )
      })
    }

    // Dropdown içeriği
    const renderDropdownContent = () => {
      switch (type) {
        case 'isyeri':
          return (
            <div className="px-1" style={{ maxHeight: '200px', overflowY: 'auto' }}>
              <p>İşyeri Seçiniz</p>
              {renderCompanyCheckboxList()}
            </div>
          )
        case 'donem':
          return (
            <div className="px-1" style={{ maxHeight: '200px', overflowY: 'auto' }}>
              <p>Dönem Seçiniz</p>
              {renderPeriodCheckboxList()}
            </div>
          )
        case 'kanun':
          return (
            <div className="px-1" style={{ maxHeight: '200px', overflowY: 'auto' }}>
              <p>Kanun Seçiniz</p>
              {renderLawCheckboxList()}
            </div>
          )
        default:
          return <DropdownItem>Varsayılan Filtre</DropdownItem>
      }
    }

    return (
      <div className="d-flex align-items-center">
        <span>{name}</span>
        <Dropdown isOpen={isOpen} toggle={toggleDropdown} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
        <DropdownToggle color="outline" className="btn-icon">
            <LuFilter size={14} />
          </DropdownToggle>
          <DropdownMenu container="body" style={{ maxHeight: '300px', overflowY: 'auto' }} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
            {renderDropdownContent()}
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }

  export default FilterComponent