import {Fragment, useState, useEffect} from "react"
// ** Reactstrap Imports
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Col,
    Button,
    ListGroup,
    ListGroupItem,
    Input
} from "reactstrap"
import {useDropzone} from "react-dropzone"
import {DownloadCloud, FileText, X} from "react-feather"
import {Controller, useForm} from "react-hook-form"
import Cleave from "cleave.js/react"
import Select from "react-select"
// ** Store & Actions
import {addGendarmeWorkerDeclarationFile, setFileType} from "./store"
import {useDispatch, useSelector} from "react-redux"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import "@styles/react/libs/file-uploader/file-uploader.scss"
import Toplu_Jandarma_Giris_Bildirimi from "../../../../assets/workerDeclaration/Toplu_Jandarma_Giris_Bildirimi.xlsx"
import Toplu_Jandarma_Cikis_Bildirimi from "../../../../assets/workerDeclaration/Toplu_Jandarma_Cikis_Bildirimi.xlsx"
import toast from "react-hot-toast"

const defaultValues = {}
const FilePopup = ({open, toggleFilePopup}) => {
    const [active, setActive] = useState("1")
    const [files, setFiles] = useState([])
    const store = useSelector(state => state.police)
    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }
    // ** Vars
    const {
        formState: {}
    } = useForm({defaultValues})
    const dispatch = useDispatch()

    const {getRootProps, getInputProps} = useDropzone({
        multiple: false,
        onDrop: result => {
            setFiles(result)
        }
    })
    const sendExcel = () => {
        if (files.length === 0) {
            toast.error("Dosya Seçiniz..")
            return
        }
        const data = new FormData()
        if (files.length > 0) {
            data.append("DeclarationFile", files[0], files[0].name)
        }
        dispatch(addGendarmeWorkerDeclarationFile(data))
    }
    const _downloadExcel = (type) => {
        const link = document.createElement("a")
        let filename = ""
        if (type === 2) {
            filename = "Toplu_Jandarma_Cikis_Bildirimi.xlsx"
            link.setAttribute("href", Toplu_Jandarma_Cikis_Bildirimi)

        } else {
            filename = "Toplu_Jandarma_Giris_Bildirimi.xlsx"
            link.setAttribute("href", Toplu_Jandarma_Giris_Bildirimi)
        }
        link.setAttribute("download", filename)
        link.click()
    }
    const renderFilePreview = file => {
        if (file.type.startsWith("image")) {
            return <img className="rounded" alt={file.name} src={URL.createObjectURL(file)} height="28" width="28"/>
        } else {
            return <FileText size="28"/>
        }
    }
    const handleRemoveFile = file => {
        const uploadedFiles = files
        const filtered = uploadedFiles.filter(i => i.name !== file.name)
        setFiles([...filtered])
    }
    const renderFileSize = size => {
        if (Math.round(size / 100) / 10 > 1000) {
            return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
        } else {
            return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
        }
    }
    const popupClose = () => {
        setFiles([])
        toggleFilePopup()
        toggle("1")
        dispatch(setFileType("1"))
    }
    const fileList = files.map((file, index) => (
        <ListGroupItem key={`${file.name}-${index}`} className="d-flex align-items-center justify-content-between">
            <div className="file-details d-flex align-items-center">
                <div className="file-preview me-1">{renderFilePreview(file)}</div>
                <div>
                    <p className="file-name mb-0">{file.name}</p>
                    <p className="file-size mb-0">{renderFileSize(file.size)}</p>
                </div>
            </div>
            <Button color="danger" outline size="sm" className="btn-icon" onClick={() => handleRemoveFile(file)}>
                <X size={14}/>
            </Button>
        </ListGroupItem>
    ))

    useEffect(() => {
        setFiles([])
        toggle("1")
        dispatch(setFileType("1"))
    }, [store.filePopupOpen])

    return (
        <>
            <Modal isOpen={open} toggle={popupClose} className="modal-dialog-centered modal-lg">
                <ModalHeader className="bg-transparent" toggle={popupClose}></ModalHeader>
                <ModalBody className="px-sm-5 mx-50 pb-5">
                    <div className="text-center mb-2">
                        <h1 className="mb-1">Jandarma Toplu Olarak Çalışan Bildirimi</h1>
                        <p>Jandarma Toplu Çalışan Bildirim Ekranı</p>
                    </div>
                    <Fragment>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    active={active === "1"}
                                    onClick={() => {
                                        toggle("1")
                                        dispatch(setFileType("1"))
                                    }}
                                >
                                    Giriş Bildirimi
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={active === "2"}
                                    onClick={() => {
                                        toggle("2")
                                        dispatch(setFileType("2"))
                                    }}
                                >
                                    Çıkış Bildirimi
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent className="py-50" activeTab={store.fileType}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col lg={12} md={12}>
                                        <Button.Ripple block outline color="warning" onClick={() => _downloadExcel(1)}>
                                            Örnek Giriş Bildirimi Excel İndir
                                        </Button.Ripple>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col lg={12} md={12}>
                                        <Button.Ripple block outline color="danger" onClick={() => _downloadExcel(2)}>
                                            Örnek Çıkış Bildirimi Excel İndir
                                        </Button.Ripple>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                        <Row>
                            <Col sm="12">
                                <div {...getRootProps({className: "dropzone"})}>
                                    <input {...getInputProps()} />
                                    <div className="d-flex align-items-center justify-content-center flex-column">
                                        <DownloadCloud size={64}/>
                                        <h5>Dosyaları buraya bırakın veya yüklemek için tıklayın</h5>
                                        <p className="text-secondary">
                                            Dosyaları buraya bırakın veya tıklayın{" "}
                                            <a href="/" onClick={e => e.preventDefault()}>
                                                Araştır
                                            </a>{" "}
                                        </p>
                                    </div>
                                </div>
                                {files.length ? (
                                    <Fragment>
                                        <ListGroup className="my-2">{fileList}</ListGroup>
                                    </Fragment>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Button type="button" className="me-1" color="primary" onClick={sendExcel}>
                                    Bildir
                                </Button>
                                <Button type="reset" color="secondary" outline onClick={popupClose}>
                                    İptal
                                </Button>
                            </Col>

                        </Row>
                    </Fragment>
                </ModalBody>
            </Modal>
        </>
    )
}
export default FilePopup
