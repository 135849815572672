/* eslint-disable no-unused-vars */
// ** React Imports
import {Fragment, useEffect, useState} from "react"
// ** Table Columns
import {columns} from "./columns"
// ** Store & Actions
import {useDispatch, useSelector} from "react-redux"
import {
    getData,
    sendApprovedVisits,
    toggleManualVisitDeclarationPopupState,
    exportExcel,
    ToggleVisitHtmlImagePopup,
    setVisitList,
    approveVisitListClear,
    approveCountPlus,
    setApproveSelectedList,
    setVisitsAutoApproval,
    getMailOptions,
    setMailOptions,
    getCompanyOptions,
    clearFilters,
    exportUnapprovedZip,
    exportVisitsZip,
    getReportEndDayArrivedMailOptions,
    setReportEndDayArrivedMailOptions
} from "../store"
// ** Third Party Components
import DataTable from "react-data-table-component"
import {ChevronDown, Copy, File, FileText, Grid, Printer, Share, Settings, Archive} from "react-feather"
import Flatpickr from "react-flatpickr"
import ReactPaginate from "react-paginate"
import ManualVisitDeclarationPopup from "../view/ManualVisitDeclarationPopup"
import VisitDetailPopup from "../view/VisitDetailPopup"
import VisitHtmlImagePopup from "../view/VisitHtmlImagePopup"
import ApprovedVisitList from "../../visit-approved/list/Table"
import ArchiveVisitList from "../list/ArchiveTable"
// ** Reactstrap Imports
import {
    Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Input,
    TabContent,
    TabPane,
    Label,
    Nav, NavItem, NavLink, Row, UncontrolledDropdown,
    Spinner
} from "reactstrap"

import Select from "react-select"

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import AutoApproveModal from "./AutoApproveModal"
import { LuFilterX } from "react-icons/lu"
import { FaRegFileZipper } from "react-icons/fa6"

// ** Table Header
const CustomHeader = ({
                          store,
                          handlePerPage,
                          rowsPerPage,
                          handleFilter,
                          searchTerm,
                          selectedRows,
                          dispatch,
                          currentPage,
                          setApproveVisitChange,
                          hasFilters
                      }) => {
    const visitsAutoApproval = useSelector(state => state.visit.visitsAutoApproval)
    const isExportZipLoading = useSelector(state => state.visit.isExportZipLoading)
    const isExportExcelLoading = useSelector(state => state.visit.isExportExcelLoading)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const user = JSON.parse(localStorage.getItem("userData"))

    const toggleModal = () => setIsModalOpen(!isModalOpen)

    const handleAutoApproveChange = (e) => {
        if (e.target.checked) {
            setIsModalOpen(true)
        } else {
            dispatch(setVisitsAutoApproval(false))
            console.log("iptal edildi")
        }
    }

    const handleConfirmAutoApprove = () => {
        setIsModalOpen(false)
        dispatch(setVisitsAutoApproval(true))
    }

    // ** Clear filters when clicked
    const handleClearFilters = () => {
        dispatch(clearFilters())
        handleFilter("")  // Eğer bir arama filtresi de varsa onu da temizle
    }


    const receiveAutomatedUnapprovedVisitMails = useSelector(state => state.visit.receiveAutomatedUnapprovedVisitMails)
    const receiveAutomatedReportEndDateArrivedVisitMails = useSelector(state => state.visit.receiveAutomatedReportEndDateArrivedVisitMails)

    const dispatch1 = useDispatch()

    const handleUnapprovedVisitMailOptionChange = () => {
        dispatch(setMailOptions(!receiveAutomatedUnapprovedVisitMails))
    }

    const handleReportEndDateArrivedMailOptionChange = () => {
        dispatch(setReportEndDayArrivedMailOptions(!receiveAutomatedReportEndDateArrivedVisitMails))
    }


    return (
        <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
            <Row>
                <Col xl="6" className="d-flex align-items-center p-0 justify-content-start">
                    <div className="d-flex align-items-center w-100">
                        <label htmlFor="rows-per-page">Toplam</label>
                        <Input
                            className="mx-50"
                            type="select"
                            id="rows-per-page"
                            value={rowsPerPage}
                            onChange={handlePerPage}
                            style={{width: "5rem"}}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </Input>
                        <label >Kayıt Göster</label>

                    </div>
                </Col>

                <Col
                    xl="6"
                    className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
                >
                    <div className="col-md-4 d-flex align-items-center mb-sm-0 mb-1 me-1">
                        <label className="mb-0" htmlFor="search-invoice">
                            Ara:
                        </label>
                        <Input
                            id="search-invoice"
                            className="ms-50 w-100"
                            type="text"
                            value={searchTerm}
                            onChange={e => handleFilter(e.target.value)}
                        />
                    </div>

                    <div className="d-flex align-items-center table-header-actions">
                    {hasFilters && (
                        <div>
                        <Button
                        className='btn-icon me-1 btn-gray d-flex align-items-center'
                        style={{ backgroundColor: 'transparent', width: "160px"}}
                            outline
                            onClick={handleClearFilters} // Clear filters on click
                        >
                            <LuFilterX size={16} className={"me-50"}/>
                            <span>Filtreleri Kaldır</span>
                        </Button>
                        </div>
                    )}
                    <Button
                        onClick={() => dispatch(exportExcel(store.data))}
                        className='btn-icon me-1 btn-gray d-flex align-items-center'
                        style={{ width: "140px" }}
                        outline
                        disabled={isExportExcelLoading}
                        >
                        {isExportExcelLoading ? (
                        <Spinner size="sm" color="primary" className={"me-50"} />
                        ) : (
                            <Grid className='font-small-4 me-50' />
                        )}
                        {isExportExcelLoading ? 'Hazırlanıyor...' : 'Excele Aktar'}
                    </Button>

                    <Button
                        onClick={() => dispatch(exportVisitsZip({data:store.data, visitType: 1}))}
                        className='btn-icon me-1 btn-gray d-flex align-items-center'
                        style={{ width: "140px" }}
                        outline
                    >
                        {isExportZipLoading ? (
                            <Spinner size="sm" color="primary" className={"me-50"} />
                        ) : (
                            <FaRegFileZipper className='font-small-4 me-50' />
                        )}
                        {isExportZipLoading ? 'Hazırlanıyor...' : 'Zipe Aktar'}
                    </Button>

                        <UncontrolledDropdown className="me-1">
                            <DropdownToggle color="secondary" caret outline>
                            <Settings className="font-small-4 me-50"/>
                            <span className="align-middle">Otomasyon Ayarları</span>
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem onClick={(e) => { e.preventDefault() }}>
                                    <div className="d-flex align-items-center">
                                        <Input
                                            type="checkbox"
                                            id="auto-approve"
                                            checked={visitsAutoApproval}
                                            onChange={handleAutoApproveChange}
                                        />
                                        <Label
                                            for="auto-approve"
                                            className="mb-0 ms-1"
                                        >
                                            Otomatik Onaylama
                                        </Label>
                                    </div>
                                </DropdownItem>

                                <DropdownItem onClick={(e) => { e.preventDefault() }}>
                                    <div className="d-flex align-items-center">
                                        <Input
                                            type="checkbox"
                                            id="receiveAutomatedUnapprovedVisitMails"
                                            checked={receiveAutomatedUnapprovedVisitMails}
                                            onChange={handleUnapprovedVisitMailOptionChange}
                                        />
                                        <Label
                                            for="auto-approve"
                                            className="mb-0 ms-1"
                                        >

                                            Yeni Eklenen Onaylanmamış Vizite Mail Bildirimi
                                        </Label>
                                    </div>
                                </DropdownItem>

                                <DropdownItem onClick={(e) => { e.preventDefault() }}>
                                    <div className="d-flex align-items-center">
                                        <Input
                                            type="checkbox"
                                            id="receiveAutomatedReportEndDateArrivedVisitMails"
                                            checked={receiveAutomatedReportEndDateArrivedVisitMails}
                                            onChange={handleReportEndDateArrivedMailOptionChange}
                                        />
                                        <Label
                                            for="auto-approve"
                                            className="mb-0 ms-1"
                                        >

                                            Rapor Bitiş Tarihi Geldi Mail Bildirimi
                                        </Label>
                                    </div>
                                </DropdownItem>

                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <Button style={{width: "150px", height: "50px"}} className="add-new-user me-1" color="primary"
                                onClick={() => dispatch1(toggleManualVisitDeclarationPopupState(true))
                                }>
                            Manuel Bildirim
                        </Button>
                        <Button style={{width: "130px", height: "50px"}} className="add-new-user me-1" color="success"
                                onClick={() => dispatch1(sendApprovedVisits(true))
                                }>
                            Onayla
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                {selectedRows.length > 0 &&
                    <SelectVisits dataSource={selectedRows} currentPage={currentPage} searchTerm={searchTerm}
                                  setApproveVisitChange={setApproveVisitChange}/>}
            </Row>
            <AutoApproveModal
    isOpen={isModalOpen}
    toggle={toggleModal}
    onConfirm={handleConfirmAutoApprove}
/>
        </div>
    )
}

const UsersList = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.visit)
    const authStore = useSelector(state => state.auth)


    // ** States
    const [active, setActive] = useState('1')
    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }
    const [sort, setSort] = useState("desc")
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [setApproveVisitChange, updateSetApproveVisitChange] = useState(1)
    const [sortColumn, setSortColumn] = useState("id")
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [selectedRows, setSelectedRows] = useState([])
    const [handleClearSelectedRows, setHandleClearSelectedRows] = useState(false)
    const toggleVisitHtmlImagePopup = () => dispatch(ToggleVisitHtmlImagePopup({show: false, image: null}))
    const [isIsyeriDropdownOpen, setIsIsyeriDropdownOpen] = useState(false)
    const [isReportStartDateDropdownOpen, setIsReportStartDateDropdownOpen] = useState(false)
    const [isReportEndDateDropdownOpen, setIsReportEndDateDropdownOpen] = useState(false)
    const [isPositionReportTypeDropdownOpen, setIsPositionReportTypeDropdownOpen] = useState(false)
    const selectedCompanyIds = useSelector(state => state.visit.selectedCompanyIds)
    const selectedReportStartDateYears = useSelector(state => state.visit.selectedReportStartDateYears)
    const selectedReportStartDateMonths = useSelector(state => state.visit.selectedReportStartDateMonths)
    const selectedReportEndDateYears = useSelector(state => state.visit.selectedReportEndDateYears)
    const selectedReportEndDateMonths = useSelector(state => state.visit.selectedReportEndDateMonths)
    const selectedPositionReportTypes = useSelector((state) => state.visit.selectedPositionReportTypes)
    const hasFilters = selectedCompanyIds.length > 0 || selectedReportStartDateYears.length > 0 || selectedReportStartDateMonths.length > 0 || selectedReportEndDateYears.length > 0 || selectedReportEndDateMonths.length > 0 || selectedPositionReportTypes.length > 0


    const toggleIsyeriDropdown = () => setIsIsyeriDropdownOpen(!isIsyeriDropdownOpen)
    const toggleReportStartDateDropdown = () => setIsReportStartDateDropdownOpen(!isReportStartDateDropdownOpen)
    const toggleReportEndDateDropdown = () => setIsReportEndDateDropdownOpen(!isReportEndDateDropdownOpen)
    const togglePositionReportTypeDropdown = () => setIsPositionReportTypeDropdownOpen(!isPositionReportTypeDropdownOpen)

    console.log(store.approvedList)
    const getDataParameter = () => {
        return {
            sort,
            sortColumn,
            q: searchTerm,
            page: currentPage,
            pageSize: rowsPerPage,
            visitType: 1,
            CompanyGroupId: authStore.selectedCompanyGroup
        }
    }

    // ** Get data on mount
    useEffect(() => {
        dispatch(getData(getDataParameter()))
        dispatch(getMailOptions())
        dispatch(getReportEndDayArrivedMailOptions())
        dispatch(approveVisitListClear())
        dispatch(getCompanyOptions(authStore.selectedCompanyGroup))

    }, [dispatch, store.data.length, authStore.selectedCompanyGroup, sort, sortColumn, currentPage, selectedCompanyIds, selectedReportStartDateYears, selectedReportStartDateMonths, selectedReportEndDateYears, selectedReportEndDateMonths, selectedPositionReportTypes])

    useEffect(() => {
        if (currentPage > 1) setCurrentPage(1)
        else dispatch(getData(getDataParameter()))
        dispatch(approveVisitListClear())
    }, [searchTerm, selectedCompanyIds, selectedReportStartDateYears, selectedReportStartDateMonths, selectedReportEndDateYears, selectedReportEndDateMonths, selectedPositionReportTypes])

    useEffect(() => {
        // dispatch(
        //     getData(getDataParameter())
        // )
    }, [])

    useEffect(() => {
        // unmount oldugunda filtreleri temizle
        return () => {
          dispatch(clearFilters())
        }
    }, [])

    // ** Function in get data on page change
    const handlePagination = page => {
        setCurrentPage(page.selected + 1)
        setHandleClearSelectedRows(!handleClearSelectedRows)
    }

    // ** Function in get data on rows per page
    const handlePerPage = e => {
        const value = parseInt(e.currentTarget.value)
        // dispatch(
        //     getData(getDataParameter())
        // )
        setRowsPerPage(value)
    }

    // ** Function in get data on search query change
    const handleFilter = val => {
        setSearchTerm(val)
        // dispatch(
        //     getData(getDataParameter())
        // )
    }

    // ** Custom Pagination
    const CustomPagination = () => {
        //const count = Number(Math.ceil(store.total / rowsPerPage))

        return (
            <ReactPaginate
                previousLabel={""}
                nextLabel={""}
                pageCount={store.total || 1}
                activeClassName="active"
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName={"page-item"}
                nextLinkClassName={"page-link"}
                nextClassName={"page-item next"}
                previousClassName={"page-item prev"}
                previousLinkClassName={"page-link"}
                pageLinkClassName={"page-link"}
                containerClassName={"pagination react-paginate justify-content-end my-2 pe-1"}
            />
        )
    }

    // ** Table data to render
    const dataToRender = () => {
        const filters = {
            q: searchTerm
        }

        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (store.data.length > 0) {
            return store.data
        } else if (store.data.length === 0 && isFiltered) {
            return []
        } else {
            return store.allData.slice(0, rowsPerPage)
        }
    }
    console.log(store.approvedList)

    const handleSort = (column, sortDirection) => {
        setSort(sortDirection)
        setSortColumn(column.sortField)
        // dispatch(
        //     getData(getDataParameter())
        // )
    }

    function onSelectedRowsChanged(item) {
        setSelectedRows(item.selectedRows)

        const newApproveSelectedList = [...store.approveSelectedList]
        newApproveSelectedList.forEach((x, index) => {
            const selectedItem = item.selectedRows.find(z => {
                return z.approveSelectList.find(y => y.value === x)
            })
            if (!selectedItem) {
                newApproveSelectedList.splice(index, 1)
            }
        })
        dispatch(setApproveSelectedList(newApproveSelectedList))

        if (item.selectedRows.length === 0) {
            dispatch(approveCountPlus(store.approveCount + 1))
        }

        updateSetApproveVisitChange(setApproveVisitChange + 1)
    }

    return (
        <Fragment>

            <Nav pills>
                <NavItem>
                    <NavLink
                        active={active === '1'}
                        onClick={() => {
                            toggle('1')
                        }}
                    >
                        Onay Bekleyen Rapor Listesi
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '2'}
                        onClick={() => {
                            toggle('2')
                        }}
                    >
                        Onaylı Rapor Listesi
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '3'}
                        onClick={() => {
                            toggle('3')
                        }}
                    >
                        Arşiv Rapor Listesi
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className='py-50' activeTab={active}>
                <TabPane tabId='1'>
                    <Card className="overflow-hidden">
                        <div className="react-dataTable">
                            <DataTable
                                noHeader
                                subHeader
                                sortServer
                                pagination
                                responsive
                                paginationServer
                                selectableRows={true}
                                selectableRowDisabled={(row) => !row.isApproved || row.reportEndDate === "-" || row.approvableStatus === 1}
                                onSelectedRowsChange={onSelectedRowsChanged}
                                clearSelectedRows={handleClearSelectedRows}
                                columns={columns(store.approveSelectedList, isIsyeriDropdownOpen, toggleIsyeriDropdown, isReportStartDateDropdownOpen, toggleReportStartDateDropdown, isReportEndDateDropdownOpen, toggleReportEndDateDropdown, isPositionReportTypeDropdownOpen, togglePositionReportTypeDropdown)}
                                onSort={handleSort}
                                sortIcon={<ChevronDown/>}
                                className="react-dataTable"
                                paginationComponent={CustomPagination}
                                data={dataToRender()}
                                subHeaderComponent={
                                    <CustomHeader
                                        store={store}
                                        searchTerm={searchTerm}
                                        rowsPerPage={rowsPerPage}
                                        handleFilter={handleFilter}
                                        handlePerPage={handlePerPage}
                                        selectedRows={selectedRows}
                                        dispatch={dispatch}
                                        currentPage={currentPage}
                                        setApproveVisitChange={setApproveVisitChange}
                                        hasFilters={hasFilters}
                                    />
                                }
                            />
                        </div>
                    </Card>
                </TabPane>
                <TabPane tabId='2'>
                    <ApprovedVisitList/>
                </TabPane>
                <TabPane tabId='3'>
                    <ArchiveVisitList/>
                </TabPane>
            </TabContent>
            <ManualVisitDeclarationPopup open={store.manualVisitDeclarationStatus}/>
            <VisitDetailPopup open={store.detailPopupStatus}/>
            <VisitHtmlImagePopup open={store.toggleVisitHtmlImagePopup?.show}
                                 toggleSidebar={toggleVisitHtmlImagePopup}/>
        </Fragment>
    )
}


function SelectVisits({dataSource, currentPage, searchTerm, setApproveVisitChange}) {
    const dispatch = useDispatch()
    const store = useSelector(state => state.visit)
    const [selectedValue, setSelectedValue] = useState({name: "default", label: "Islem tipi seciniz"})
    const options = [
        {
            value: -1,
            label: "Seçiniz"
        },
        {
            value: 1,
            label: "Çalıştı olarak isaretle"
        },
        {
            value: 0,
            label: "Çalışmadı olarak isaretle"
        }
    ]

    function setApproveList(item) {
        const updatedItemList = [
            ...store.data.map(x => ({
                ...x,
                approveSelectList: x.approveSelectList.map(z => ({...z, selected: false}))
            }))
        ]
        const approveSelectedList = []
        const approveVisitList = []
        updatedItemList.forEach(x => {
            const selectedIndex = dataSource.findIndex(z => z.reportNo === x.reportNo)
            if (selectedIndex === -1) return x

            const approvelist = x.approveSelectList.filter(z => !z.isDisabled)
            const selectedDataList = approvelist.filter(z => parseInt(z.value.substring(11)) === item.value)
            if (selectedDataList.length > 0) {
                const selectedData = selectedDataList[selectedDataList.length - 1]
                x.approveSelectList.some(z => {
                    if (z.value === selectedData.value) {
                        approveSelectedList.push(z.value)
                        approveVisitList.push({
                            qualityState: z.value,
                            identificationNumber: x.identificationNumber,
                            case: x.case,
                            medulaReportId: x.medularReportId,
                            date: x.reportEndDate,
                            visitId: x.visitId
                        })
                        return true
                    }
                    return false
                })
            }
            return x
        })
        dispatch(setVisitList(approveVisitList))
        dispatch(setApproveSelectedList(approveSelectedList))
    }

    function onChange(item) {
        setSelectedValue(item)
        setApproveList(item)
    }

    useEffect(() => {
        onChange({value: -1, label: "Seçiniz"})
        dispatch(approveVisitListClear())
    }, [currentPage, store.approveCount, searchTerm])


    useEffect(() => {
        setApproveList(selectedValue)
    }, [setApproveVisitChange])


    return (
        <div style={{display: "flex"}}>
            <div className="col-1 mt-2">Secilenleri</div>
            <div className="col-4 mt-1">
                <Select
                    isClearable={false}
                    options={options}
                    onChange={onChange}
                    className="react-select"
                    classNamePrefix="select"
                    value={selectedValue}
                />
            </div>
        </div>
    )
}

export default UsersList
