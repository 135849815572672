import {
  Card,
  CardHeader,
  Modal,
  ModalBody,
  Form,
  ModalHeader,
  Row,
  Col,
  Label,
  Button,
  Input,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import { useEffect, useState } from "react"

import { store } from "@store/store"

import Cleave from 'cleave.js/react'
import { ChevronDown, Settings, Edit } from "react-feather"
import DataTable from "react-data-table-component"
import axios from "axios"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { setCompanyServiceAccess, setUpdateId, setPasswordShow } from "../store"


import "@styles/react/libs/tables/react-dataTable-component.scss"

const selectServiceData = [{ value: 0, label: "SGK" }, { value: 1, label: "Jandarma" }, { value: 2, label: "Emniyet" }]
const selectServiceTypePoliceData = [{ value: 1, label: "Emniyet (Konaklama Sektörü)" }, { value: 2, label: "Emniyet" }]
export const columns = [
  {
    name: "Servis",
    selector: row => row.serviceType,
    cell: row => {
      if (row.serviceType === 0) return "SGK"
      if (row.serviceType === 2) return "Emniyet"
      if (row.serviceType === 1) return "Jandarma"
    }
  },
  {
    name: "Hata Mesajı",
    selector: row => row.errorMessage,
    cell: row => row.errorMessage
  },
  {
    name: "İşlemler",
    minWidth: "172px",
    sortField: "name",
    selector: row => row.id,
    cell: row => {
      return <div className="column-action">
        <UncontrolledDropdown>
          <DropdownToggle tag="div" className="btn btn-sm">
            <Settings size={14} className="cursor-pointer" />
          </DropdownToggle>
          <DropdownMenu container="body">
            <DropdownItem
                tag="a"
                href="/"
                id={row.id}
                className="w-100"
                onClick={e => {
                  e.preventDefault()
                  store.dispatch(setUpdateId(row.id))
                  store.dispatch(setPasswordShow(true))
                }}
            >
              <Edit size={14} className="me-50" />
              <span className="align-middle">Düzenle</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    }
  }
]

const CompanyServiceAccesses = ({ id }) => {
  const [passwordData, setPasswordData] = useState([])
  const [serviceTypeList, setServiceTypeList] = useState([])
  const [serviceType, setServiceType] = useState(null)
  const dispatch = useDispatch()
  const storeCompany = useSelector(state => state.company)
  const [selectedOption, setSelectedOption] = useState(null)

  const [username, setUsername] = useState("")
  const [suffix, setSuffix] = useState("")
  const [companyPassword, setCompanyPassword] = useState("")
  const [systemPassword, setSystemPassword] = useState("")

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption)
  }

  const optionsTcKimlikNo = { blocks: [11], numericOnly: true }

  const getPassword = () => {
    axios.get(`/api/companyserviceaccess?companyId=${id}&decryptData=true`).then(item => {
      const typeList = []
      item.data.companyServices.forEach(element => {
        typeList.push(element.serviceType)
      })
      setServiceTypeList(typeList)
      setPasswordData(item.data.companyServices)
    })
  }

  const handleReset = () => {
    dispatch(setUpdateId(-1))
    dispatch(setPasswordShow(!storeCompany.passwordShow))
    setServiceType(null)
    setSelectedOption(null)
  }

  useEffect(() => {
    getPassword()
  }, [])

  useEffect(() => {
    getPassword()
    dispatch(setPasswordShow(false))
    setServiceType(null)
    setSelectedOption(null)
  }, [storeCompany.refreshData])

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.target)
    dispatch(
        setCompanyServiceAccess({
          CompanyId: id,
          ServiceType: serviceType ? serviceType.value : -1,
          Username: data.get('username'),
          Suffix: data.get('suffix'),
          RegistrationNumber: data.get('registrationNumber'),
          SystemPassword: data.get('systemPassword'),
          CompanyPassword: data.get('companyPassword'),
          PoliceDeclarationServiceType: selectedOption?.value ?? 0
        })
    )
  }

  useEffect(() => {
    if (serviceType !== null) {
      const passwordEntry = passwordData.find(x => x.serviceType === serviceType.value)
      if (passwordEntry) {
        setUsername(passwordEntry.username)
        setSuffix(passwordEntry.suffix)
        setCompanyPassword(passwordEntry.companyPassword)
        setSystemPassword(passwordEntry.systemPassword)
      }
    }
  }, [serviceType, passwordData])

  const handleInputChange = (e, setter) => {
    setter(e.target.value)
  }

  return (
      <>
        <Card>
          <CardHeader tag="h4">Şifreler
            <Button color="primary" onClick={() => dispatch(setPasswordShow(true))}>
              Ekle
            </Button>
          </CardHeader>
          <div className="react-dataTable user-view-account-projects">
            <DataTable
                noHeader
                responsive
                columns={columns}
                data={passwordData}
                className="react-dataTable"
                sortIcon={<ChevronDown size={10} />}
            />
          </div>
        </Card>

        <Modal isOpen={storeCompany.passwordShow} toggle={handleReset} className="modal-dialog-centered modal-lg">
          <ModalHeader className="bg-transparent" toggle={handleReset}></ModalHeader>
          <ModalBody className="px-sm-5 pt-50 pb-5">
            <div className="text-center mb-2">
              <h1 className="mb-1">Şifre Ekle</h1>
            </div>
            <Form onSubmit={handleSubmit}>
              <Row className="gy-1 pt-75">
                <Col xs={12}>
                  <Label className="form-label" for="serviceType">
                    Servis
                  </Label>
                  <Select
                      isClearable={false}
                      options={(storeCompany.serviceAccessUpdateId !== -1 && passwordData.filter(x => x.id === storeCompany.serviceAccessUpdateId).length > 0 ? selectServiceData.filter(z => z.value === passwordData.filter(x => x.id === storeCompany.serviceAccessUpdateId)[0].serviceType) : selectServiceData.filter(z => !serviceTypeList.includes(z.value)))}
                      className="react-select"
                      classNamePrefix="select"
                      id="serviceType"
                      onChange={data => setServiceType(data)}
                  />
                </Col>
                {serviceType?.value === 2 && <>
                  <Col xs={12}>
                    <Label className="form-label" for="applyType">
                      Bildirim Türü <span className="text-danger">*</span>
                    </Label>
                    <Select
                        isClearable={false}
                        options={selectServiceTypePoliceData}
                        className="react-select"
                        classNamePrefix="select"
                        value={selectedOption}
                        onChange={handleSelectChange} />
                  </Col>
                  {selectedOption?.value === 1 ? <Col xs={12}>
                    <Label className="form-label" for="registrationNumber">
                      TC Kimlik No
                    </Label>
                    <Cleave className='form-control' id="registrationNumber" options={optionsTcKimlikNo} />
                  </Col> : null}
                </>}
                <Col xs={12}>
                  <Label className="form-label" for="username">
                    Kullanıcı Adı
                  </Label>
                  <Input id="username" name="username"
                         value={username}
                         onChange={e => handleInputChange(e, setUsername)} // handle change here
                  />
                </Col>
                {serviceType?.value === 0 ? <>
                  <Col xs={12}>
                    <Label className="form-label" for="suffix">
                      İşyeri Kodu
                    </Label>
                    <Input id="suffix" name="suffix"
                           value={suffix}
                           onChange={e => handleInputChange(e, setSuffix)} // handle change here
                    />
                  </Col>
                  <Col xs={12}>
                    <Label className="form-label" for="companyPassword">
                      İşyeri Şifresi
                    </Label>
                    <Input id="companyPassword" name="companyPassword"
                           value={companyPassword}
                           onChange={e => handleInputChange(e, setCompanyPassword)} // handle change here
                    />
                  </Col>
                </> : null}
                <Col xs={12}>
                  <Label className="form-label" for="systemPassword">
                    Sistem Şifresi
                  </Label>
                  <Input id="systemPassword" name="systemPassword"
                         value={systemPassword}
                         onChange={e => handleInputChange(e, setSystemPassword)} // handle change here
                  />
                </Col>

                <Col xs={12} className="text-center mt-2 pt-50">
                  <Button type="submit" className="me-1" color="primary">
                    Kaydet
                  </Button>
                  <Button
                      type="reset"
                      color="secondary"
                      outline
                      onClick={handleReset}>
                    İptal
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </>
  )
}

export default CompanyServiceAccesses
