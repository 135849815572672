export const columns = [
    {
        name: 'GRUP ADI',
        sortable: false,
        minWidth: '140px',
        sortField: 'companyGroupName',
        selector: row => row.companyGroupName,
        cell: row => (row.companyGroupName.length > 20 ? `${row.companyGroupName.substring(0, 17)}...` : row.companyGroupName)
    },
    {
        name: 'İSİM SOYİSİM',
        sortable: false,
        minWidth: '140px',
        sortField: 'userName',
        selector: row => row.userName,
        cell: row => row.userName
    },
    {
        name: 'AÇIKLAMA',
        sortable: false,
        minWidth: '140px',
        sortField: 'description',
        selector: row => row.description,
        cell: row => row.description
    },
    {
        name: 'SON GİRİŞ TARİHİ',
        sortable: false,
        minWidth: '140px',
        sortField: 'lastAccessDate',
        selector: row => row.lastAccessDate,
        cell: row => row.lastAccessDate
    },
    {
        name: 'KAÇ KEZ ZİYARET EDİLDİ',
        sortable: false,
        minWidth: '140px',
        sortField: 'actionCount',
        selector: row => row.actionCount,
        cell: row => row.actionCount
    }

]

