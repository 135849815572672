import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Button, Label, Form, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { editData, toggleUpdatePopupClose} from './store'
import { useDispatch, useSelector } from 'react-redux'
import '@styles/react/libs/flatpickr/flatpickr.scss'

const SidebarEditCompanyGroup = ({open}) => {
  const store = useSelector(state => state.companyGroups)
  const dispatch = useDispatch()
  const [groupName, setGroupName] = useState(store.selectedCompanyGroupName)

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: store.selectedCompanyGroupName
    }
  })

  useEffect(() => {
    setGroupName(store.selectedCompanyGroupName)
  }, [store.selectedCompanyGroupName])

  const onSubmit = data => {
    console.log('Form submit edildi. Gönderilen data:', data)

    dispatch(
      editData({
        name: data.name,
        id: store.selectedCompanyGroupId
      })
    )
    dispatch(toggleUpdatePopupClose(false))
  }

  return (
    <Modal
      isOpen={open}
      toggle={() => dispatch(toggleUpdatePopupClose(false))}
      className='modal-dialog-centered modal-lg'
    >
      <ModalHeader
        className='bg-transparent'
        toggle={() => dispatch(toggleUpdatePopupClose(false))}
      />
      <ModalBody className='px-sm-5 mx-50 pb-5'>
        <div className='text-center mb-2'>
          <h1 className='mb-1'>Grup</h1>
          <p>Grup güncelleme ekranı</p>
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-1'>
            <Label className='form-label' for='name'>
              Grup Adı<span className='text-danger'>*</span>
            </Label>
            <Controller
              name="name"
              control={control}
              defaultValue={groupName}
              render={({ field }) => (
                <Input
                  {...field}
                  id='name'
                  value={field.value || groupName}
                  onChange={(e) => {
                    field.onChange(e)
                    setGroupName(e.target.value)
                  }}
                  invalid={errors.name && true}
                />
              )}
            />
          </div>
          <Button type='submit' className='me-1' color='primary'>
            Kaydet
          </Button>
          <Button
            type='reset'
            color='secondary'
            outline
            onClick={() => dispatch(toggleUpdatePopupClose(false))}
          >
            İptal
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default SidebarEditCompanyGroup