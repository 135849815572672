// ** React Imports
import { Fragment, useEffect, useState } from 'react'


// ** Table Columns
import { columns } from './columns'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import {  getData, getResultData } from '../store'

// ** Third Party Components
import DataTable from 'react-data-table-component'
import { ChevronDown, Copy, File, FileText, Grid, Printer, Share } from 'react-feather'
import ReactPaginate from 'react-paginate'


// ** Reactstrap Imports
import {
  Button, Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input,
  Label, Row, UncontrolledDropdown, Spinner
} from 'reactstrap'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'

// ** Table Header
const CustomHeader = ({ handlePerPage, rowsPerPage }) => {
    return (
      <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
        <Row>
          <Col xl='6' className='d-flex align-items-center p-0'>
            <div className='d-flex align-items-center w-100'>
              <label htmlFor='rows-per-page'>Toplam</label>
              <Input
                className='mx-50'
                type='select'
                id='rows-per-page'
                value={rowsPerPage}
                onChange={handlePerPage}
                style={{ width: '5rem' }}
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
              </Input>
              <label htmlFor='rows-per-page'>kayıt göster</label>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

const ResultList = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.company)
  const authStore = useSelector(state => state.auth)
  const user = useSelector(state => state.auth.userData)
  const resultStore = useSelector(state => state.debtQuestioning)

  // ** States
  const [sort, setSort] = useState('desc')
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState('')
//   const [SearchRegistrationNumber, setSearchRegistrationNumber] = useState('')
//   const [searchName, setSearchName] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [SearchGroupName, setSearchGroupName] = useState('')
//   const [searchTaxNumber, setSearchTaxNumber] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [sortColumn, setSortColumn] = useState('id')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(true)
  //

  const getDataParameter = () => {
    if (user.role === "ConsultantAdmin") {
      return {
        sort,
        sortColumn,
        q: searchTerm,
        page: currentPage,
        pageSize: rowsPerPage,
        // registrationNumber: SearchRegistrationNumber,
        name: searchName,
        groupName: SearchGroupName,
        taxNumber: searchTaxNumber
      }
    } else {
      return {
        sort,
        sortColumn,
        q: searchTerm,
        page: currentPage,
        pageSize: rowsPerPage,
        // registrationNumber: SearchRegistrationNumber,
        name: searchName,
        groupName: SearchGroupName,
        taxNumber: searchTaxNumber,
        companyGroupId: user.companyGroupList[0].value
      }
    }

  }

  const getListData = () => {
    setLoading(true)
    dispatch(getResultData(authStore.selectedCompanyGroup))
    setLoading(false)
  }
    useEffect(() => {
      dispatch(getResultData(authStore.selectedCompanyGroup))
    }, [authStore.selectedCompanyGroup])

  // ** Get data on mount
  useEffect(() => {
    getListData()
  }, [dispatch, store.data.length, authStore.selectedCompanyGroup])

  // ** Function in get data on page change
  const handlePagination = page => {
    setCurrentPage(page.selected + 1) // Update current page state
  }


  // ** Function in get data on rows per page
  const handlePerPage = e => {
    const value = parseInt(e.currentTarget.value)
    setRowsPerPage(value) // Update rowsPerPage state
    setCurrentPage(1) // Reset to the first page
  }


  // ** Custom Pagination
  const CustomPagination = () => {
    const totalPages = Math.ceil(resultStore.resultData.length / rowsPerPage)

    return (
      <ReactPaginate
        previousLabel={''}
        nextLabel={''}
        pageCount={totalPages || 1}
        activeClassName='active'
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={page => handlePagination(page)}
        pageClassName={'page-item'}
        nextLinkClassName={'page-link'}
        nextClassName={'page-item next'}
        previousClassName={'page-item prev'}
        previousLinkClassName={'page-link'}
        pageLinkClassName={'page-link'}
        containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
      />
    )
  }


  // ** Table data to render
  const dataToRender = () => {
    const startIndex = (currentPage - 1) * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return resultStore.resultData.slice(startIndex, endIndex) // Slice data for the current page
  }


  const handleSort = (column, sortDirection) => {
    debugger
    setSort(sortDirection)
    setSortColumn(column.sortField)
    dispatch(
      getData(getDataParameter())
    )
  }

  return (
    <Fragment>
      {/* <Card>
        <CardHeader>
          <CardTitle tag='h4'>Filtreler</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            {user.role === "ConsultantAdmin" ? <Col md='3'>
                <Label for='role-select'>Grup Adı</Label>
                <Input name='text' id='username' rows='3' placeholder=''
                  className='react-select'
                  onChange={data => {
                    setSearchGroupName(data.target.value)
                  }} />
              </Col> : null
            }
            <Col className='my-md-0 my-1' md='3'>
              <Label for='plan-select'>Firma Adı</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchName(data.target.value)
                }} />
            </Col>
            <Col md='3'>
              <Label for='status-select'>Sicil Numarası</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchRegistrationNumber(data.target.value)
                }} />
            </Col>
            <Col md='3'>
              <Label for='status-select'>Vergi Numarası</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchTaxNumber(data.target.value)
                }} />
            </Col>
          </Row>
        </CardBody>
      </Card> */}

      <Card className='overflow-hidden'>
        <div className='react-dataTable'>
          <DataTable
            noHeader
            subHeader
            sortServer
            pagination
            responsive
            paginationServer
            columns={columns}
            onSort={handleSort}
            sortIcon={<ChevronDown />}
            className='react-dataTable'
            paginationComponent={CustomPagination}
            progressPending={loading}
            progressComponent={<Spinner type='grow' color='primary' />}
            data={dataToRender()}
            subHeaderComponent={
              <CustomHeader
                rowsPerPage={rowsPerPage}
                handlePerPage={handlePerPage}
              />
            }
          />
        </div>
      </Card>
    </Fragment>
  )
}

export default ResultList
