// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from "react-hot-toast"


export const togglePopup = createAsyncThunk('api/togglePopup', async (status) => {
    return status
})

export const DissmisalClose = createAsyncThunk('api/DissmisalClose', async () => {
    return false
})

export const DissmisalUser = createAsyncThunk('api/DissmisalUser', async (data) => {
    return data
})

export const setFilePopupOpen = createAsyncThunk('api/setFilePopupOpen', async (status) => {
    return status
})

export const setFileType = createAsyncThunk('api/FileType', async (type) => {
    return type
})

export const getData = createAsyncThunk('workerdeclaration/getData', async (params, {getState}) => {
    params.CompanyGroupId = getState().auth.selectedCompanyGroup
    params.WorkerDeclarationType = 1
    const response = await axios.get(`/api/workerdeclaration`, {params})
    return {
        params,
        data: response.data.workerDeclarations,
        totalPages: response.data.pageCount
    }
})

export const getGendarmerieCountryData = createAsyncThunk('workerdeclaration/getGendarmerieCountryData', async () => {
    const response = await axios.get('/api/commonvalue?moduleType=1&groupType=1')
    const selectData = []
    response.data.commonValues.map((item) => {
        selectData.push({label: item.name, value: item.value})
    })
    return selectData
})

export const getStaffTypeData = createAsyncThunk('workerdeclaration/getStaffTypeData', async () => {
    const response = await axios.get('/api/commonvalue?moduleType=2&groupType=1')
    const selectData = []
    response.data.commonValues.map((item) => {
        selectData.push({label: item.name, value: item.value})
    })
    return selectData
})

export const addWorkerDeclaration = createAsyncThunk('api/workerdeclaration', async (data, {dispatch, getState}) => {
    const response = await axios.post('/api/workerdeclaration', data)
    if (response && response.status === 200) {
        await dispatch(getData(getState().gendarmerie.params))
        toast.success("İşleminiz başlatıldı.")
        dispatch(togglePopup(false))
    }
    return police
})

export const removeWorkerDeclaration = createAsyncThunk('api/workerdeclaration', async (data, { dispatch, getState }) => {
    const response = await axios.post('/api/workerdeclaration/dissmisalnotify', data)
    if (response && response.status === 200) {
        await dispatch(DissmisalClose())
        await dispatch(getData(getState().police.params))
        toast.success("İşleminiz başlatıldı.")
    }
    return police
})

export const addWorkerDeclarationFile = createAsyncThunk('api/workerdeclarationfile', async (data, { dispatch, getState }) => {
    let fileUrl = `create`
    if (getState().police.fileType === "2") fileUrl = `delete`
    const response = await axios.post(`/api/workerdeclaration/file/${fileUrl}`, data)
    if (response && response.status === 200) {
        await dispatch(getData(getState().police.params))
        toast.success("İşleminiz başlatıldı.")
        dispatch(setFilePopupOpen(false))
    }
    return response
})

export const addGendarmeWorkerDeclarationFile = createAsyncThunk('api/addGendarmeWorkerDeclarationFile', async (data, { dispatch, getState }) => {
    let fileUrl = `create`
    if (getState().police.fileType === "2") fileUrl = `delete`
    const response = await axios.post(`/api/workerdeclaration/file/gendarme/${fileUrl}`, data)
    if (response && response.status === 200) {
        await dispatch(getData(getState().police.params))
        toast.success("İşleminiz başlatıldı.")
        dispatch(setFilePopupOpen(false))
    }
    return response
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, {dispatch, getState}) => {
    await axios.delete('/apps/users/delete', {id})
    await dispatch(getData(getState().gendarmerie.params))
    return id
})
export const getCompanyList = createAsyncThunk('api/getCompanyList', async (data, {getState}) => {
    const response = await axios.get(`/api/companygroup/${getState().auth.selectedCompanyGroup}/companies`)
    return response.data
})

export const appWorkerDeclarationGendarmerieSlice = createSlice({
    name: 'appWorkerDeclarationsGendarmerie',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        selectedUser: null,
        dissmisalPopup: false,
        gendarmerieCountryData: [],
        staffTypeData: [],
        companyList: [],
        sidebarOpen: false,
        filePopupOpen: false,
        fileType: "1"
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getGendarmerieCountryData.fulfilled, (state, action) => {
                state.gendarmerieCountryData = action.payload
            })
            .addCase(DissmisalUser.fulfilled, (state, action) => {
                state.selectedUser = action.payload
                state.dissmisalPopup = true
            })
            .addCase(setFilePopupOpen.fulfilled, (state, action) => {
                state.filePopupOpen = action.payload
            })
            .addCase(setFileType.fulfilled, (state, action) => {
                state.fileType = action.payload
            })
            .addCase(DissmisalClose.fulfilled, (state, action) => {
                state.dissmisalPopup = action.payload
            })
            .addCase(togglePopup.fulfilled, (state, action) => {
                state.sidebarOpen = action.payload
            })
            .addCase(getStaffTypeData.fulfilled, (state, action) => {
                state.staffTypeData = action.payload
            })
            .addCase(getCompanyList.fulfilled, (state, action) => {
                state.companyList = action.payload
            })
    }
})

export default appWorkerDeclarationGendarmerieSlice.reducer
