// ** Redux Imports
import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {createTab} from "../../../../redux/tabManagement"
// ** Axios Imports
import axios from "axios"
import toast from "react-hot-toast"

export const getData = createAsyncThunk("visit/getData", async (params, {getState}) => {
    let url = `/api/visits/0?`
    const selectedCompanyIds = getState().visit.selectedCompanyIds
    const selectedReportStartDateYears = getState().visit.selectedReportStartDateYears
    const selectedReportStartDateMonths = getState().visit.selectedReportStartDateMonths
    const selectedReportEndDateYears = getState().visit.selectedReportEndDateYears
    const selectedReportEndDateMonths = getState().visit.selectedReportEndDateMonths
    const selectedPositionReportTypes = getState().visit.selectedPositionReportTypes

    if (selectedCompanyIds.length > 0) {
        url += selectedCompanyIds.map(id => `&SelectedCompanyIds=${id}`).join('')
        console.log(url)
    }

    if (selectedReportStartDateYears.length > 0) {
        url += selectedReportStartDateYears.map(id => `&SelectedReportStartDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedReportStartDateMonths.length > 0) {
        url += selectedReportStartDateMonths.map(id => `&SelectedReportStartDateMonths=${id}`).join('')
        console.log(url)

    }

    if (selectedReportEndDateYears.length > 0) {
        url += selectedReportEndDateYears.map(id => `&SelectedReportEndDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedReportEndDateMonths.length > 0) {
        url += selectedReportEndDateMonths.map(id => `&SelectedReportEndDateMonths=${id}`).join('')
        console.log(url)
    }

    if (selectedPositionReportTypes.length > 0) {
        url += selectedPositionReportTypes.map(id => `&SelectedPositionReportTypes=${id}`).join('')
        console.log(url)
    }

    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.visits,
        totalPages: response.data.pageCount,
        visitsAutoApproval: response.data.visitsAutoApproval
    }

})

export const getMailOptions = createAsyncThunk("visit/getMailOptions", async () => {
    const url = `/api/Visits/GetUnapprovedVisitMailOptions/`
    const response = await axios.get(url)
    console.log("ressponnseeee", response.data)
    return response.data
})

export const setMailOptions = createAsyncThunk("visit/setMailOptions", async (data) => {
    const url = `/api/Visits/SetUnapprovedVisitMailOptions/`
    const response = await axios.post(url, {
        receiveAutomatedUnapprovedVisitMails: data
    })
    return response.data
})

export const getReportEndDayArrivedMailOptions = createAsyncThunk("visit/getReportEndDayArrivedMailOptions", async () => {
    const url = `/api/Visits/GetReportEndDayArrivedMailOptions/`
    const response = await axios.get(url)
    console.log("reponse", response.data)
    return response.data
})

export const setReportEndDayArrivedMailOptions = createAsyncThunk("visit/setReportEndDayArrivedMailOptions", async (data) => {
    const url = `/api/Visits/SetReportEndDayArrivedMailOptions/`
    const response = await axios.post(url, {
        receiveAutomatedReportEndDayArrivedMails: data
    })
    return response.data
})

export const getArchiveData = createAsyncThunk("visit/getArchiveData", async (params, {getState}) => {
    let url = `/api/visits/0?`
    const selectedCompanyIds = getState().visit.selectedCompanyIds
    const selectedReportStartDateYears = getState().visit.selectedReportStartDateYears
    const selectedReportStartDateMonths = getState().visit.selectedReportStartDateMonths
    const selectedReportEndDateYears = getState().visit.selectedReportEndDateYears
    const selectedReportEndDateMonths = getState().visit.selectedReportEndDateMonths
    const selectedPositionReportTypes = getState().visit.selectedPositionReportTypes

    if (selectedCompanyIds.length > 0) {
        url += selectedCompanyIds.map(id => `&SelectedCompanyIds=${id}`).join('')
        console.log(url)
    }

    if (selectedReportStartDateYears.length > 0) {
        url += selectedReportStartDateYears.map(id => `&SelectedReportStartDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedReportStartDateMonths.length > 0) {
        url += selectedReportStartDateMonths.map(id => `&SelectedReportStartDateMonths=${id}`).join('')
        console.log(url)

    }

    if (selectedReportEndDateYears.length > 0) {
        url += selectedReportEndDateYears.map(id => `&SelectedReportEndDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedReportEndDateMonths.length > 0) {
        url += selectedReportEndDateMonths.map(id => `&SelectedReportEndDateMonths=${id}`).join('')
        console.log(url)
    }

    if (selectedPositionReportTypes.length > 0) {
        url += selectedPositionReportTypes.map(id => `&SelectedPositionReportTypes=${id}`).join('')
        console.log(url)
    }

    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.visits,
        totalPages: response.data.pageCount
    }

})

export const clearFilters = createAction('edeclarations/clearFilters')


export const ToggleVisitHtmlImagePopup = createAsyncThunk("visit/ToggleVisitHtmlImagePopup", (data) => {
    return data
})

export const toggleManualVisitDeclarationPopupState = createAsyncThunk("visit/popUpState", (status) => {
    return status
})

export const formResetState = createAsyncThunk("visit/formResetState", (status) => {
    return status
})


export const manualVisitDeclaration = createAsyncThunk("api/outSgkWorkerDeclaration", async (data, {
    dispatch,
    getState
}) => {
    const response = await axios.post("/api/visits/manualDeclaration", data)
    if (response.status === 200) {
        await dispatch(formResetState(!getState().visit.formReset))
        toast.success("İşleminiz Başlatıldı")
    }
    await dispatch(getData(getState().visit.params))

    return response
})

export const DeleteLossVisit = createAsyncThunk("Visit/DeleteLossVisit", async (data, {dispatch, getState}) => {
    const response = await axios.post("/api/visits/DeleteLossVisit", {id: data})
    if (response.status === 200) {
        toast.success("İşleminiz Başarılı")
        await dispatch(getData(getState().visit.params))
    }

    return response
})
export const exportExcel = createAsyncThunk("UnapprovedVisit/exportExcel", async (data, {getState}) => {
    console.log(data)
    const selectedCompanyIds = getState().visit.selectedCompanyIds
    const selectedReportStartDateYears = getState().visit.selectedReportStartDateYears
    const selectedReportStartDateMonths = getState().visit.selectedReportStartDateMonths
    const selectedReportEndDateYears = getState().visit.selectedReportEndDateYears
    const selectedReportEndDateMonths = getState().visit.selectedReportEndDateMonths
    const selectedPositionReportTypes = getState().visit.selectedPositionReportTypes

    let url = `/api/visits/UnapprovedExport?`

    if (selectedCompanyIds.length > 0) {
        url += selectedCompanyIds.map(id => `&SelectedCompanyIds=${id}`).join('')
        console.log(url)
    }

    if (selectedReportStartDateYears.length > 0) {
        url += selectedReportStartDateYears.map(id => `&SelectedReportStartDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedReportStartDateMonths.length > 0) {
        url += selectedReportStartDateMonths.map(id => `&SelectedReportStartDateMonths=${id}`).join('')
        console.log(url)

    }

    if (selectedReportEndDateYears.length > 0) {
        url += selectedReportEndDateYears.map(id => `&SelectedReportEndDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedReportEndDateMonths.length > 0) {
        url += selectedReportEndDateMonths.map(id => `&SelectedReportEndDateMonths=${id}`).join('')
        console.log(url)
    }

    if (selectedPositionReportTypes.length > 0) {
        url += selectedPositionReportTypes.map(id => `&SelectedPositionReportTypes=${id}`).join('')
        console.log(url)
    }

    const response = await axios.get(url, {
        responseType: "arraybuffer",
        "Content-Type": "blob",
        params: {CompanyGroupId: getState().auth.selectedCompanyGroup, Q: getState().workAccident.params.q}
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "onay_bekleyen_vizite_listesi.xlsx")
        document.body.appendChild(link)
        link.click()
    }

})

export const exportVisitsZip = createAsyncThunk("UnapprovedVisit/exportZip", async ({data, visitType}, {getState}) => {
    console.log(data)
    console.log("visitType", visitType)
    const selectedCompanyIds = getState().visit.selectedCompanyIds
    const selectedReportStartDateYears = getState().visit.selectedReportStartDateYears
    const selectedReportStartDateMonths = getState().visit.selectedReportStartDateMonths
    const selectedReportEndDateYears = getState().visit.selectedReportEndDateYears
    const selectedReportEndDateMonths = getState().visit.selectedReportEndDateMonths
    const selectedPositionReportTypes = getState().visit.selectedPositionReportTypes

    let url = `/api/visits/VisitsZipExport?`

    if (selectedCompanyIds.length > 0) {
        url += selectedCompanyIds.map(id => `&SelectedCompanyIds=${id}`).join('')
        console.log(url)
    }

    if (selectedReportStartDateYears.length > 0) {
        url += selectedReportStartDateYears.map(id => `&SelectedReportStartDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedReportStartDateMonths.length > 0) {
        url += selectedReportStartDateMonths.map(id => `&SelectedReportStartDateMonths=${id}`).join('')
        console.log(url)

    }

    if (selectedReportEndDateYears.length > 0) {
        url += selectedReportEndDateYears.map(id => `&SelectedReportEndDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedReportEndDateMonths.length > 0) {
        url += selectedReportEndDateMonths.map(id => `&SelectedReportEndDateMonths=${id}`).join('')
        console.log(url)
    }

    if (selectedPositionReportTypes.length > 0) {
        url += selectedPositionReportTypes.map(id => `&SelectedPositionReportTypes=${id}`).join('')
        console.log(url)
    }

    const response = await axios.get(url, {
        responseType: "arraybuffer",
        "Content-Type": "blob",
        params: {
            CompanyGroupId: getState().auth.selectedCompanyGroup,
            Q: getState().workAccident.params.q,
            VisitType: visitType
        }
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        if (visitType === 0) {
            link.setAttribute("download", "hero_onaylanmis_vizite.zip")
        }
        if (visitType === 1) {
            link.setAttribute("download", "hero_onay_bekleyen_vizite.zip")
        }
        if (visitType === 5) {
            link.setAttribute("download", "hero_arsiv_vizite.zip")
        }
        document.body.appendChild(link)
        link.click()
    }

})

export const exportArchivedExcel = createAsyncThunk("ArchiveVisit/exportExcel", async (data, {getState}) => {
    console.log(data)
    const selectedCompanyIds = getState().visit.selectedCompanyIds
    const selectedReportStartDateYears = getState().visit.selectedReportStartDateYears
    const selectedReportStartDateMonths = getState().visit.selectedReportStartDateMonths
    const selectedReportEndDateYears = getState().visit.selectedReportEndDateYears
    const selectedReportEndDateMonths = getState().visit.selectedReportEndDateMonths
    const selectedPositionReportTypes = getState().visit.selectedPositionReportTypes

    let url = `/api/visits/ArchivedExport?`

    if (selectedCompanyIds.length > 0) {
        url += selectedCompanyIds.map(id => `&SelectedCompanyIds=${id}`).join('')
        console.log(url)
    }

    if (selectedReportStartDateYears.length > 0) {
        url += selectedReportStartDateYears.map(id => `&SelectedReportStartDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedReportStartDateMonths.length > 0) {
        url += selectedReportStartDateMonths.map(id => `&SelectedReportStartDateMonths=${id}`).join('')
        console.log(url)

    }

    if (selectedReportEndDateYears.length > 0) {
        url += selectedReportEndDateYears.map(id => `&SelectedReportEndDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedReportEndDateMonths.length > 0) {
        url += selectedReportEndDateMonths.map(id => `&SelectedReportEndDateMonths=${id}`).join('')
        console.log(url)
    }

    if (selectedPositionReportTypes.length > 0) {
        url += selectedPositionReportTypes.map(id => `&SelectedPositionReportTypes=${id}`).join('')
        console.log(url)
    }

    const response = await axios.get(url, {
        responseType: "arraybuffer",
        "Content-Type": "blob",
        params: {CompanyGroupId: getState().auth.selectedCompanyGroup, Q: getState().workAccident.params.q}
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "arsiv_vizite_listesi.xlsx")
        document.body.appendChild(link)
        link.click()
    }

})

export const approveVisit = createAsyncThunk("visit/approveVisit", (data, {getState}) => {
    const approveNewList = [...getState().visit.approvedList]
    const value = data.qualityState.split("-")[0]
    debugger
    if (value !== "0") {
        const qualityState = data.qualityState.includes("-") ? data.qualityState.split("-")[1] : data.qualityState
        if (qualityState === "1" || qualityState === "0" || qualityState === "2") {
            const newData = approveNewList.filter(x => x.visitId !== data.visitId)
            newData.push(data)
            return newData
        }
    } else {
        const identificationNumber = data.qualityState.split("-")[1]
        const newData = approveNewList.filter(x => x.identificationNumber !== identificationNumber)
        return newData
    }
})

export const setVisitList = createAsyncThunk("visit/setVisitList", (request, {getState}) => {
    const approveNewList = [...getState().visit.approvedList]
    const newDataList = []
    request.forEach(data => {
        const value = data.qualityState.split("-")[0]
        if (value !== "0") {
            const qualityState = data.qualityState.split("-")[1]
            if (qualityState === "1" || qualityState === "0" || qualityState === "2") {
                // const newData = approveNewList.filter(x => x.visitId !== data.visitId)
                newDataList.push(data)
            }
        } else {
            const identificationNumber = data.qualityState.split("-")[1]
            const newData = approveNewList.filter(x => x.identificationNumber !== identificationNumber)
            newDataList.push(newData)
        }
    })
    debugger
    return newDataList
})

export const approveVisitListClear = createAsyncThunk("visit/approveVisitListClear", () => {
    return []
})

export const approveCountPlus = createAsyncThunk("visit/approveCountPlus", (data) => {
    return data
})
export const setApproveSelectedList = createAsyncThunk("visit/setApproveSelectedList", (data) => {
    return data
})

export const getCompanyList = createAsyncThunk("visit/getCompanyList", async (data, {getState}) => {
    const response = await axios.get(`/api/companygroup/${getState().auth.selectedCompanyGroup}/companies`)
    return response.data
})

export const sendApprovedVisits = createAsyncThunk("visit/sendApprovedVisit", async (data, {dispatch, getState}) => {
    toast.loading('İşlem Tamamlanana Kadar Bekleyiniz...')
    const response = await axios.post("/api/visits/approveVisit", {visits: getState().visit.approvedList})
    toast.remove()
    if (response.status === 200) {
        await dispatch(getData(getState().visit.params))
        toast.success("İşleminiz başlamıştır!")
    }
    return response
})

export const getDetail = createAsyncThunk("visit/getDetail", async (data) => {
    const response = await axios.get(`/api/visits/getVisitDetail?tckn=${data.tckn}`)
    return {popupStatus: true, responseData: response}
})

export const detailPopupState = createAsyncThunk("visit/detailPopupState", (data, {dispatch}) => {
    dispatch(getDetail(data))
})

export const detailPopupStateClose = createAsyncThunk("visit/detailPopupStateClose", (data) => {
    return data
})

export const getApprovedVisitList = createAsyncThunk("visit/getApprovedVisitList", (data, {dispatch}) => {
    dispatch(createTab(data))
})
export const getCompanyDetails = createAsyncThunk("user/getCompanyDetails", (data, {dispatch}) => {
    console.log(data)
    dispatch(createTab(data))
})

export const getHtmlFile = createAsyncThunk("visit/getHtmlFile", async (data, {getState}) => {
    const response = await axios.get(`/api/visits/HtmlFile`, {
        responseType: "arraybuffer",
        "Content-Type": "blob",
        params: {ReportTrackingNo: getState().visit.toggleVisitHtmlImagePopup.visitReportNumber, VisitType: 1}
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "HtmlFile.html")
        document.body.appendChild(link)
        link.click()
    }

})

export const setVisitsAutoApproval = createAsyncThunk("visit/setVisitsAutoApproval", async (data, {getState}) => {
    const companyGroupId = getState().auth.selectedCompanyGroup
    console.log(companyGroupId)
    const response = await axios.post(`/api/Visits/UpdateCompanyGroupVisitsAutoApprovalStatus`, {
        companyGroupId,
        visitsAutoApproval: data
    })
    return response
})

export const getCompanyOptions = createAsyncThunk('visit/getCompanyOptions', async (companyGroupId) => {
    const response = await axios.get(`/api/visits/CompanyOptions?companyGroupId=${companyGroupId}`)
    return response.data.companyOptions
})

export const appVisitSlice = createSlice({
    name: "visit",
    initialState: {
        data: [],
        dataArchive: [],
        total: 1,
        totalArchive: 1,
        params: {},
        paramsArchive: {},
        allData: [],
        allDataArchive: [],
        manualVisitDeclarationStatus: false,
        approvedList: [],
        detailPopupStatus: false,
        formReset: false,
        detailTckn: 0,
        approveCount: 0,
        detailVisitData: [],
        companyList: [],
        selectedData: [],
        toggleVisitHtmlImagePopup: null,
        approveSelectedList: [],
        visitsAutoApproval: false,
        receiveAutomatedUnapprovedVisitMails: false,
        companyOptions: [],
        selectedCompanyIds: [],
        selectedReportStartDateYears: [],
        selectedReportStartDateMonths: [],
        selectedReportEndDateYears: [],
        selectedReportEndDateMonths: [],
        isExportZipLoading: false,
        isExportExcelLoading: false,
        selectedPositionReportTypes: [],
        receiveAutomatedReportEndDateArrivedVisitMails: false
    },
    reducers: {
        setSelectedCompanies: (state, action) => {
            state.selectedCompanyIds = action.payload
        },
        setSelectedReportStartDateYears: (state, action) => {
            state.selectedReportStartDateYears = action.payload
        },
        setSelectedReportStartDateMonths: (state, action) => {
            state.selectedReportStartDateMonths = action.payload
        },
        setSelectedReportEndDateYears: (state, action) => {
            state.selectedReportEndDateYears = action.payload
        },
        setSelectedReportEndDateMonths: (state, action) => {
            state.selectedReportEndDateMonths = action.payload
        },
        setSelectedPositionReportTypes: (state, action) => {
            state.selectedPositionReportTypes = action.payload
            console.log("state.selectedPositionReportTypes", state.selectedPositionReportTypes)
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
                state.visitsAutoApproval = action.payload.visitsAutoApproval
            })
            .addCase(getArchiveData.fulfilled, (state, action) => {
                state.dataArchive = action.payload.data
                state.paramsArchive = action.payload.params
                state.totalArchive = action.payload.totalPages
            })
            .addCase(toggleManualVisitDeclarationPopupState.fulfilled, (state, action) => {
                state.manualVisitDeclarationStatus = action.payload
            })
            .addCase(approveVisit.fulfilled, (state, action) => {
                const newApproveSelectedList = []
                if (action.payload !== undefined) {
                    action.payload.forEach(x => newApproveSelectedList.push(x.qualityState))
                }
                state.approveSelectedList = newApproveSelectedList
                state.approvedList = action.payload
            })
            .addCase(approveVisitListClear.fulfilled, (state, action) => {
                state.approveSelectedList = []
                state.approvedList = action.payload
            })
            .addCase(setVisitList.fulfilled, (state, action) => {
                state.approvedList = action.payload
            })
            .addCase(setApproveSelectedList.fulfilled, (state, action) => {
                state.approveSelectedList = action.payload
            })
            .addCase(sendApprovedVisits.fulfilled, (state, action) => {
                state.approvedList = []
                state.approveCount = state.approveCount + 1
                console.log(action.payload)
            })
            .addCase(approveCountPlus.fulfilled, (state, action) => {
                state.approveCount = action.payload
            })
            .addCase(detailPopupStateClose.fulfilled, (state, action) => {
                state.detailPopupStatus = action.payload
            })
            .addCase(getDetail.fulfilled, (state, action) => {
                state.detailPopupStatus = action.payload.popupStatus
                state.detailVisitData = action.payload.responseData
            })
            .addCase(getCompanyList.fulfilled, (state, action) => {
                state.companyList = action.payload
            })
            .addCase(formResetState.fulfilled, (state, action) => {
                state.formReset = action.payload
            })
            .addCase(ToggleVisitHtmlImagePopup.fulfilled, (state, action) => {
                state.toggleVisitHtmlImagePopup = action.payload
            })
            .addCase(setVisitsAutoApproval.fulfilled, (state, action) => {
                state.visitsAutoApproval = action.payload.data
                console.log("actionpayload", action.payload)
                if (action.payload.status === 200) {
                    toast.success("İşleminiz Başarılı")
                } else {
                    toast.error("İşleminiz Başarısız")
                }
            })
            .addCase(getMailOptions.fulfilled, (state, action) => {
                state.receiveAutomatedUnapprovedVisitMails = action.payload.receiveAutomatedUnapprovedVisitMails
            })
            .addCase(setMailOptions.fulfilled, (state, action) => {
                state.receiveAutomatedUnapprovedVisitMails = action.payload
                toast.success("İşleminiz Başarılı")
            })
            .addCase(getReportEndDayArrivedMailOptions.fulfilled, (state, action) => {
                state.receiveAutomatedReportEndDateArrivedVisitMails = action.payload.receiveAutomatedReportEndDayArrivedMails
            })
            .addCase(setReportEndDayArrivedMailOptions.fulfilled, (state, action) => {
                state.receiveAutomatedReportEndDateArrivedVisitMails = action.payload
                toast.success("İşleminiz Başarılı")
            })
            .addCase(getCompanyOptions.fulfilled, (state, action) => {
                state.companyOptions = action.payload
            })
            .addCase(clearFilters, (state) => {
                state.selectedCompanyIds = []
                state.selectedReportStartDateYears = []
                state.selectedReportStartDateMonths = []
                state.selectedReportEndDateYears = []
                state.selectedReportEndDateMonths = []
                state.selectedPositionReportTypes = []
            })
            .addCase(exportVisitsZip.pending, (state) => {
                state.isExportZipLoading = true
            })
            .addCase(exportVisitsZip.fulfilled, (state) => {
                state.isExportZipLoading = false
            })
            .addCase(exportVisitsZip.rejected, (state) => {
                state.isExportZipLoading = false
            })
            .addCase(exportExcel.pending, (state) => {
                state.isExportExcelLoading = true
            })
            .addCase(exportExcel.fulfilled, (state) => {
                state.isExportExcelLoading = false
            })
            .addCase(exportExcel.rejected, (state) => {
                state.isExportExcelLoading = false
            })
            .addCase(exportArchivedExcel.pending, (state) => {
                state.isExportExcelLoading = true
            })
            .addCase(exportArchivedExcel.fulfilled, (state) => {
                state.isExportExcelLoading = false
            })
            .addCase(exportArchivedExcel.rejected, (state) => {
                state.isExportExcelLoading = false
            })
    }
})

export const {
    setSelectedCompanies,
    setSelectedReportStartDateYears,
    setSelectedReportStartDateMonths,
    setSelectedReportEndDateYears,
    setSelectedReportEndDateMonths,
    setSelectedPositionReportTypes
} = appVisitSlice.actions


export default appVisitSlice.reducer
