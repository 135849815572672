import Table from './Table'
import '@styles/react/apps/app-users.scss'

const UserLogs = () => {
    return (
        <div className='app-user-list'>
            <Table/>
        </div>
    )
}

export default UserLogs
